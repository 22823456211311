export const config =
  process.env.NODE_ENV === "production"
    ? {
        apiBase: "https://api.feastr.de",
        oauth2: {
          clientId: "ed9f62e7-ae6d-452c-8437-f1d66cbc988c",
          redirect: "https://admin2.feastr.de/callback.html",
          url: "https://id.feastr.de/oauth2/authorize",
        },
      }
    : {
        apiBase: "https://api.feastr-dev.de",
        oauth2: {
          clientId: "d1e35a86-fd05-4ac9-b320-e2408c050066",
          redirect: "http://localhost:3000/callback.html",
          url: "https://id.feastr-dev.de/oauth2/authorize",
        },
      };
